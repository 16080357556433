@import '../../styles/variables.scss';

.inputWrapper {
    position: relative;
}

.input {
    padding: 0 16px;
    width: 100%;
    height: 56px;

    color: $dark-font;
    font-size: .7rem;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .5px;

    border: none;
    border-bottom: 1px solid $secondary-element;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #F5F5F5;

    transition: color .2s ease-in, border-width .2s ease-in, border-color .2s ease-in, background-color .2s ease-in;

    box-sizing: border-box;

    &:not(&_disabled, &:focus):hover {
        border-color: $black-element;
        background-color: $disabled-element;
    }

    &:focus {
        border-bottom-width: 2px;
        border-color: $primary-element;
        background-color: #DCDCDC;

        & + .input-label {
            top: 7px;

            color: $primary-font;
        }
    }

    &_disabled {
        color: $secondary-font;
        border-color: $disabled-element;
        background-color: $disabled-element;

        cursor: not-allowed;
    }

    &-label {
        position: absolute;
        top: 20px;
        left: 16px;

        color: $dark-font;
        font-size: .7rem;
        font-weight: 400;
        line-height: 100%;
        text-decoration: none;
        letter-spacing: .5px;

        transition: top .1s ease-in;

        &_force {
            top: 7px;
        }
    }
}