@import '../../../styles/variables.scss';

.personalContainer {
  &-title {
    margin: 10px 0;

    font-size: 2.15rem;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 1.5rem;
    }
  }

  &-subtitle {
    margin: 30px 0 15px 0;

    font-size: 1rem;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 0.8rem;
    }
  }

  &-input {
    margin-bottom: 10px;
    width: 300px;

    @media (max-width: 992px) {
      width: auto;
    }
  }

  &-cardinfo {
    padding: 0 10px;
    margin-top: 12px;
    display: block;
    width: 300px;
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  &-card {
    margin-top: 20px;
    width: 300px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: help;

    & span:nth-child(1) {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
    
    & span:nth-child(2) {
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
    }
    
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}