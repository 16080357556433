@import './reset.css';
@import './variables.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap&subset=cyrillic');

* {
  font-family: 'Roboto Condensed', sans-serif !important;
}

.CLASSIC {
  background-color: $success-element;
}

.SILVER {
  background-color: silver;
}

.GOLD {
  background-color: gold;
}

.PLATINUM {
  background-color: powderblue;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.75);
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.slideFromLeft-enter {
  transform: translateX(-110%);
}

.slideFromLeft-enter.slideFromLeft-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in;
}

.slideFromLeft-exit {
  transform: translateX(0);
}

.slideFromLeft-exit.slideFromLeft-exit-active {
  transform: translateX(-110%);
  transition: transform 300ms ease-in;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--selected {
  background-color: $primary-element;
}

.react-datepicker__current-month {
  margin: 20px 0;
  color: $dark-font;
}

details summary::-webkit-details-marker {
  font-size: .8rem !important;
}