@import '../../styles/variables.scss';

.navContainer {
  position: absolute;
  top: 0;
  left: 0;

  padding: 0 3%;
  width: 100%;
  height: 90px;

  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0 7%;
  }

  &-picture {
    margin-right: 25px;
    height: 55px;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.navList {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  &-row {
    margin: 0;
    padding: 3px 0;
    width: 100%;
    height: 45px;

    display: inline-flex;
    flex-direction: row;

    &:nth-child(1) {
      overflow-x: auto;

      align-items: flex-end;

      font-size: 0.8rem;
      font-weight: 700;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 992px) {
        font-size: 0.6rem;
      }
    }

    &:nth-child(2) {
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 16px;

      @media (max-width: 992px) {
        font-size: 0.6rem;
      }
    }
  }

  &-link {
    margin-right: 6px;

    color: $dark-font;
    text-transform: uppercase;

    cursor: pointer;

    &_active {
      margin-right: 12px !important;
      color: $primary-font;
      font-size: 0.75rem;
      font-weight: 700;
    }
  }
}
