@import '../../../styles/variables.scss';

.userContainer {
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  &-picture {
    margin: 15px auto;
    width: 80%;
    height: auto;

    display: block;
  }

  &-form {
    width: 300px;
  }

  &-btn {
    width: 100%;
  }

  &-link {
    margin: 14px 0 8px 0;

    display: block;

    color: $primary-font;
    font-size: 0.75rem;
    font-weight: 400;

    cursor: pointer;
  }
}
