@import '../../styles/variables.scss';

.btn {
    padding: 0 16px;
    min-width: 64px;
    max-width: 100%;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
    text-decoration: none;

    border-radius: 4px;

    transition: color .2s ease-in, background-color .2s ease-in;

    cursor: pointer;

    &_primary {
        color: $white-font;

        border: 1px solid $primary-element;
        background-color: $primary-element;
    }

    &_secondary {
        color: $primary-element;
        
        border: 1px solid $primary-element;
        background-color: $white-element;
    }

    &_success {
        color: $white-font;

        border: 1px solid $success-element;
        background-color: $success-element;
    }

    &_danger {
        color: $white-font;

        border: 1px solid $danger-element;
        background-color: $danger-element;
    }

    &_textOnly {
        color: $dark-font;
        
        border: 1px solid $white-element;
        background-color: $white-element;
    }

    &_disabled {
        color: $secondary-font;
        
        border: 1px solid $disabled-element;
        background-color: $disabled-element;

        cursor: not-allowed;
    }

    &_size_sm {
        height: 32px;

        font-size: .7rem;
        letter-spacing: .5px;
    }

    &_size_md {
        height: 42px;

        font-size: .8rem;
        letter-spacing: .6px;
    }
}