@import '../../styles/variables.scss';

.chip {
    padding: 0 12px;
    max-width: 100%;
    height: 32px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: $dark-font;
    font-size: .7rem;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: .5px;
    white-space: nowrap;
    text-transform: uppercase;

    border-radius: 16px;
    background-color: $disabled-element;

    transition: color .2s ease-in, background-color .2s ease-in;

    cursor: pointer;

    &_active {
        color: $white-font;
        background-color: $primary-element;
    }
}