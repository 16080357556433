@import '../../../styles/variables.scss';

.stockContainer {
}

.stockFilter {
  margin-top: 45px;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 992px) {
    overflow-x: auto;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }
}

.stockGrid {
  margin-top: 45px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 120px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.stockCard {
  display: inline-grid;
  grid-template-columns: 120px auto;
  grid-template-rows: 100%;
  cursor: pointer;

  &-picture {
    background-size: 70%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &-body {
    padding: 16px 12px;
  }

  &-title {
    overflow: hidden;

    margin-bottom: 5px;
    height: 28px;

    color: $dark-font;
    font-size: 0.9rem;
    font-weight: 700;

    @media (max-width: 992px) {
      font-size: 0.7rem;
    }
  }

  &-wysiwyg {
    overflow: hidden;
    height: 45px;

    h3 {
      margin-bottom: 15px;
      color: $dark-font;
      font-size: 1.25rem;

      @media (max-width: 992px) {
        font-size: 1rem;
      }
    }

    p {
      color: $dark-font;
      font-size: 0.9rem;
      font-weight: 300;
      line-height: 110%;

      @media (max-width: 992px) {
        font-size: 0.7rem;
      }
    }

    li {
      margin-top: 5px;
      color: $dark-font;
      font-size: 1rem;
      font-weight: 100;
      line-height: 130%;

      @media (max-width: 992px) {
        font-size: 0.8rem;
      }
    }

    img {
      display: none;
    }
  }
}
