@import '../../../styles/variables.scss';

.personalBookingContainer {
  &-title {
    margin: 10px 0;

    font-size: 2.15rem;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 1.5rem;
    }
  }
}

.bookingContainer {
  margin-top: 45px;
}

.bookingCard {
  overflow: hidden;

  margin-bottom: 45px;
  height: 400px;

  display: flex;

  @media (max-width: 992px) {
    height: auto;
    flex-direction: column;
  }

  &-picture {
    padding: 30px 3%;
    width: 90vw;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    background-size: cover;
    background-position: center center;

    @media (max-width: 992px) {
      margin-bottom: 15px;
      width: 100%;
      height: 300px;
    }
  }

  &-payment {
    margin-top: 15px;
    display: block;
    color: $danger-font;
    font-size: .9rem;
    font-weight: 400;
  }

  &-title {
    color: $white-font;
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 1.5rem;
    }
  }

  &-caption {
    margin: 20px 0 10px 0;
    color: $dark-font;
    font-size: 0.9rem !important;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 0.7rem !important;
    }

    & span {
      color: $dark-font;
      font-size: 0.9rem !important;
      font-weight: 300 !important;
      text-transform: uppercase;

      @media (max-width: 992px) {
        font-size: 0.7rem !important;
      }
    }
  }

  &-body {
    position: relative;
    padding: 20px 3%;
    width: 60vw;
    flex-grow: 1;

    @media (max-width: 992px) {
      width: 100%;
    }
  }
}
