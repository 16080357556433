@import '../../styles/variables.scss';

.profileContainer {
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: row;

  background-color: $white-element;

  &-body {
    overflow-y: scroll;
    
    padding: 95px 3% 30px 3%;
    width: 100%;

    background-color: $white-element;

    @media (max-width: 992px) {
      padding: 30px 7% 30px 7%;
    }
  }
}

.profileNav {
  position: relative;
  overflow: hidden;
  width: 250px;
  height: 100vh;
  flex-shrink: 0;
  background-color: $light-element;
  transition: width .25s linear;

  &_minimized {
    width: 60px;
  }

  &-picture {
    margin: 15px auto;
    width: 70%;
    height: auto;
    display: block;

    @media (max-width: 992px) {
      width: 50%;
    }
  }

  &-link {
    padding: 16px 14px;

    display: block;

    color: $dark-font;
    font-size: .75rem;
    font-weight: 400;
    line-height: 110%;
    white-space: nowrap;

    transition: background-color .25s linear, color .25s linear;

    &:hover, &_active {
      background-color: $disabled-element;
    }

    &_mini {
      padding: 0;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-btn {
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    @media (max-width: 992px) {
      display: none;
    }
  }
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $white-element;
}