@import '../../styles/variables.scss';

.dropdownContainer {
  // position: relative;

  // margin: 2px 0;
  // padding: 0 12px;
  // width: 100%;
  // height: 50px;

  // display: flex;
  // align-items: center;
  // box-shadow: 0 1px 2px 0 hsla(0, 0%, 0%, .2);

  // cursor: pointer;

  // @media (max-width: 992px) {
  //   width: 100%;
  // }

  // &-title {
  //   color: $dark-font;
  //   font-size: .9rem;
  //   font-weight: 700;
  //   text-transform: uppercase;

  //   @media (max-width: 992px) {
  //     font-size: .7rem;
  //   }
  // }
  margin-top: 45px;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 992px) {
    overflow-x: auto;
    justify-content: flex-start;
    flex-flow: row nowrap;
  }
}

.dropdownList {
  position: absolute;
  top: 52px;
  left: 0;
  z-index: 2;
  overflow: scroll;

  width: 100%;
  max-height: 300px;

  background-color: $white-element;
  box-shadow: 0 1px 2px 0 hsla(0, 0%, 0%, .2);

  &-title {
    padding: 0 12px;
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    
    color: $dark-font;
    font-size: .9rem;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: .7rem;
    }
  }
}
