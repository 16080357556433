@import '../../styles/variables.scss';

.footerContainer {
  position: relative;
  // height: 100%;
  
  padding: 30px 3%;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  background-color: $light-element;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }

  &-picture {
    margin-right: 25px;
    height: 55px;
  }
}

.footerNav {
  @media (max-width: 992px) {
    margin-top: 25px;
  }

  &-title {
    color: $dark-font;
    font-size: .9rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &-link {
    margin-top: 10px;

    display: block;

    color: $dark-font;
    font-size: .75rem;
    font-weight: 400;
    text-transform: uppercase;

    cursor: pointer;

    @media (max-width: 992px) {
      
    }
  }
}
