@import '../../../styles/variables.scss';

.newsContainer {
}

.newsGrid {
  margin-top: 45px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.newsCard {
  border-bottom: 4px solid $black-element;
  box-shadow: 0 1px 2px 0 hsla(0, 0%, 0%, 0.2);
  transition: border 0.4s linear;

  &:hover {
    border-color: $primary-element;
  }

  @media (max-width: 992px) {
    margin-bottom: 45px;
  }

  &-picture {
    height: 200px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &-body {
    padding: 22px;
  }

  &-title {
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 700;

    @media (max-width: 992px) {
      font-size: 0.8rem;
    }
  }

  &-caption {
    margin-bottom: 8px;

    display: block;

    color: $secondary-font;
    font-size: 0.9rem;
    font-weight: 400;

    @media (max-width: 992px) {
      font-size: 0.7rem;
    }
  }

  &-wysiwyg {
    overflow: hidden;
    height: 80px;

    h3 {
      margin-bottom: 15px;
      color: $dark-font;
      font-size: 1.25rem;

      @media (max-width: 992px) {
        font-size: 1rem;
      }
    }

    p {
      color: $dark-font;
      font-size: 1rem;
      font-weight: 300;
      line-height: 130%;

      @media (max-width: 992px) {
        font-size: 0.8rem;
      }
    }

    li {
      margin-top: 5px;
      color: $dark-font;
      font-size: 1rem;
      font-weight: 100;
      line-height: 130%;

      @media (max-width: 992px) {
        font-size: 0.8rem;
      }
    }

    img {
      display: none;
    }
  }

  &-btn {
    margin-top: 10px;
    padding: 0;

    &:hover {
      color: $primary-font;
    }
  }
}
