html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas,details, figcaption, figure,  footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;

    font-size: 100%;
    
    border: 0;
    outline: 0;

    vertical-align: baseline;
    background: transparent;

    box-sizing: border-box;
}

body {
    line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav,section { 
    display: block;
}

nav ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

a {
    margin: 0;
    padding: 0;

    font-size: 100%;

    text-decoration: none;
    vertical-align: baseline;
    background: transparent;
}

ins {
    background-color:#FF9;
    color:#000;
    text-decoration: none;
}

mark {
    color:#000; 
    font-style: italic;
    font-weight: bold;

    background-color:#FF9;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    margin: 1em 0;
    padding: 0;
    height: 1px;

    display: block;
    
    border: 0;
    border-top: 1px solid #CCCCCC;
}

input, select {
    /* -webkit-appearance: none; */
    outline: none;
    vertical-align: middle;
    box-sizing: border-box;
}