@import '../../styles/variables.scss';

.mainContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: $white-element;

  &-title {
    margin: 10px 0;

    font-size: 2.15rem;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 1.5rem;
    }
  }

  &-text {
    font-size: 1rem;
    font-weight: 100;
    line-height: 120%;
    letter-spacing: 0.5px;

    @media (max-width: 992px) {
      font-size: 0.9rem;
    }
  }

  &-media {
    position: relative;

    width: 100%;
    height: 100vh;

    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &-video {
    position: fixed;
    top: 50%;
    left: 50%;

    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;

    transform: translateX(-50%) translateY(-50%);
  }

  &-picture {
    position: fixed;

    max-width: 80%;
    width: 450px;
    height: auto;
  }

  &-icon {
    position: fixed;

    max-width: 50px;
    width: 450px;
    height: auto;

    fill: $white-element;

    animation: up-down 2s infinite;
  }

  &-body {
    position: relative;

    padding: 110px 3% 30px 3%;
    margin-top: -90px;
    width: 100%;
    min-height: 90vh;

    background-color: $white-element;

    @media (max-width: 768px) {
      padding: 110px 7% 30px 7%;
    }
  }
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $white-element;
}

@keyframes up-down {
  from {
    bottom: 12%;
  }

  50% {
    bottom: 15%;
  }

  to {
    bottom: 12%;
  }
}