@import '../../styles/variables.scss';

.preview {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 10;
  overflow: hidden;

  max-width: 80%;
  max-height: 60vh;
  width: 600px;
  height: 600px;
  // margin: 5% auto;

  display: flex;
  flex-direction: column;

  box-shadow: 0 3px 5px 0 hsla(0, 0%, 0%, 0.2);
  border-radius: 8px;
  background-color: $white-element;

  transform: translate(-50%, -50%);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &-picture {
    margin: 0 auto;
    width: auto;
    height: 300px;

    @media (max-width: 992px) {
      height: 200px;
    }
  }

  &-body {
    padding: 22px 7%;

    @media (max-width: 992px) {
      padding: 25px 7% 16px 7%;
    }
  }

  &-title {
    margin-bottom: 10px;
    font-size: 1.25em;
    line-height: 130%;
    font-weight: 700;

    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }

  &-caption {
    margin-bottom: 10px;
    display: block;
    color: $secondary-font;
    font-size: 0.9rem;
    font-weight: 400;

    @media (max-width: 992px) {
      font-size: 0.7rem;
    }
  }

  &-wysiwyg {
    overflow-y: auto;
    height: 220px;
    color: $dark-font;
    word-wrap: break-word;

    @media (max-width: 992px) {
      height: 160px;
    }

    h1,
    h2,
    h3 {
      margin-bottom: 15px;
      color: $dark-font;
      font-size: 1.25rem;

      @media (max-width: 992px) {
        font-size: 1rem;
      }
    }

    p {
      color: $dark-font;
      font-size: 1rem;
      font-weight: 300;
      line-height: 130%;

      @media (max-width: 992px) {
        font-size: 0.8rem;
      }
    }

    li {
      margin-top: 5px;
      color: $dark-font;
      font-size: 1rem;
      font-weight: 100;
      line-height: 130%;

      @media (max-width: 992px) {
        font-size: 0.8rem;
      }
    }

    img {
      margin: 15px 0;
      width: 50%;
      height: auto;
    }
  }
}
