@import '../../../styles/variables.scss';

.contactsContainer {
  &-caption {
    margin: 20px 0 10px 0;
    color: $dark-font;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 0.8rem;
    }
  }

  &-link {
    margin: 10px 0;

    display: block;

    color: $primary-font;
    font-size: 0.9rem;
    font-weight: 400;

    @media (max-width: 992px) {
      font-size: 0.7rem;
    }
  }
}

.docsContainer {
  margin-top: 45px;
}

.docsCard {
  margin-bottom: 15px;

  &[open] &-title ~ * {
    animation: sweep 0.5s ease-in-out;
  }

  &-title {
    color: $dark-font;
    font-size: 0.9rem;
    font-weight: 400;

    @media (max-width: 992px) {
      font-size: 0.7rem;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &-caption {
    margin: 20px 0 10px 0;
    color: $dark-font;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 0.8rem;
    }
  }

  &-wysiwyg {
    padding: 20px 0;

    h3 {
      margin-bottom: 15px;
      color: $dark-font;
      font-size: 1.25rem;

      @media (max-width: 992px) {
        font-size: 1rem;
      }
    }

    p {
      color: $dark-font;
      font-size: 0.9rem;
      font-weight: 300;
      line-height: 130%;

      @media (max-width: 992px) {
        font-size: 0.7rem;
      }
    }

    li {
      margin-top: 5px;
      color: $dark-font;
      font-size: 1rem;
      font-weight: 100;
      line-height: 130%;

      @media (max-width: 992px) {
        font-size: 0.8rem;
      }
    }

    img {
      display: none;
    }
  }
}

@keyframes sweep {
  0% {
      opacity: 0;
      transform: translateY(-1.25em);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}