@import '../../styles/variables.scss';

.sideSheet {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: auto;

  max-width: 90%;
  width: 50vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  box-shadow: 0 3px 5px 0 hsla(0, 0%, 0%, 0.2);
  background-color: $white-element;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 992px) {
    width: 320px;
  }

  &-picture {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }

  &-body {
    padding: 22px 7%;

    @media (max-width: 992px) {
      padding: 25px 7% 16px 7%;
    }
  }

  &-title {
    margin-bottom: 10px;
    font-size: 1.25em;
    line-height: 130%;
    font-weight: 700;

    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }

  &-caption {
    margin-bottom: 10px;
    display: block;
    color: $secondary-font;
    font-size: 0.9rem;
    font-weight: 400;

    @media (max-width: 992px) {
      font-size: 0.7rem;
    }
  }

  &-wysiwyg {
    color: $dark-font;
    word-wrap: break-word;

    h1,
    h2,
    h3 {
      margin-bottom: 15px;
      color: $dark-font;
      font-size: 1.25rem;

      @media (max-width: 992px) {
        font-size: 1rem;
      }
    }

    p {
      color: $dark-font;
      font-size: 1rem;
      font-weight: 300;
      line-height: 130%;

      @media (max-width: 992px) {
        font-size: 0.8rem;
      }
    }

    li {
      margin-top: 5px;
      color: $dark-font;
      font-size: 1rem;
      font-weight: 100;
      line-height: 130%;

      @media (max-width: 992px) {
        font-size: 0.8rem;
      }
    }

    img {
      margin: 15px 0;
      width: 50%;
      height: auto;
    }
  }
}
